(function () {
  'use strict';

  angular
    .module('register')
    .config(config);

  function config($stateProvider) {
    $stateProvider
		.state('register', {
			url: '/register?usersid',
				params:{
					usersid:{value:'new', squash: true}
				},
			views:{
					alerts: {
					   templateUrl: 'alerts/alerts.tpl.html',
					   controller: 'AlertsCtrl',
					   controllerAs: 'alerts'
					},
					main:{
						templateUrl: 'register/register.tpl.html',
						controller: 'RegisterCtrl',
						controllerAs: 'register'
					},
					footer: {
						templateUrl: 'footer/footer.tpl.html',
						controller: 'FooterCtrl',
						controllerAs: 'footer'
					}
				}
			});
	}
}());
